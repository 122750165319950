/**
 * Debounce function decorator
 *
 * @param delay
 * @param shouldInvokeImmediately
 * @returns the function debounced
 */
export function Debouncer(delay = 200, immediate = false): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = debounce(originalMethod, delay, immediate);
    return descriptor;
  };
}

/**
 *
 * @param func the function to debounce
 * @param delay milliseconds after last invocation before execution happens
 * @param immediate if the function should be invoked on first call
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce(func: Function, delay = 200, immediate = false) {
  let timerId: any;
  return function (...args: any): any {
    let invokedImmediately = false;

    const self = this;

    return new Promise(resolve => {
      const handleResult = (result: any) =>
        result instanceof Promise ? result.then(res => resolve(res)) : resolve(result);

      if (immediate && !timerId) {
        invokedImmediately = true;
        handleResult(func.apply(self, args));
      }
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        if (!invokedImmediately) {
          handleResult(func.apply(self, args));
        }
      }, delay);
    });
  };
}
